import React from "react";
import Icon from '@ant-design/icons';
import EyeSvg from '../assets/svg-icons/pswd_eye.svg';
import EyeInvisibleSvg from '../assets/svg-icons/pswd_invisible_eye.svg';
import IntroSvg from '../assets/intro.svg';
import SignupIndvSvg from '../assets/svg-icons/signup_individual.svg';
import SignupOrgSvg from '../assets/svg-icons/signup_org.svg';
import SignupRepSvg from '../assets/svg-icons/signup_rep.svg';
import InfoSvg from '../assets/svg-icons/info.svg';
import CheckCircleSvg from '../assets/svg-icons/check_circle.svg';
import NoCasesSvg from '../assets/svg-icons/no-cases.svg';
import CheckGreenSvg from '../assets/svg-icons/check_green.svg';
import NoEventsSvg from '../assets/svg-icons/no-events.svg';
import HelpOutlineSvg from  '../assets/svg-icons/help_outline_info.svg';
import StarSvg from  '../assets/svg-icons/star.svg';
import StarFillSvg from  '../assets/svg-icons/star_fill.svg';

export const IntroIcon = props => <Icon component={IntroSvg} style={{ fontSize: 245 }} {...props} />;
export const EyeIcon = props => <Icon component={EyeSvg} style={{ fontSize: 24}} {...props} />;
export const EyeInvisibleIcon = props => <Icon component={EyeInvisibleSvg} style={{ fontSize: 24}} {...props} />;
export const SignupIndvIcon = props => <Icon component={SignupIndvSvg} style={{ fontSize: 40}} {...props} />;
export const SignupOrgIcon = props => <Icon component={SignupOrgSvg} style={{ fontSize: 40}} {...props} />;
export const SignupRepIcon = props => <Icon component={SignupRepSvg} style={{ fontSize: 40}} {...props} />;
export const InfoIcon = props => <Icon component={InfoSvg} style={{ fontSize: 16}} {...props} />;
export const CheckCircleIcon = props => <Icon component={CheckCircleSvg} style={{ fontSize: 24, marginLeft: 'auto'}} {...props} />;
export const NoCasesIcon = props => <Icon component={NoCasesSvg} style={{ fontSize: 140}} {...props} />;
export const CheckGreenIcon = props => <Icon component={CheckGreenSvg} style={{ fontSize: 16}} {...props} />;
export const NoEventsIcon = props => <Icon component={NoEventsSvg} style={{ fontSize: 140}} {...props} />;
export const HelpOutlineIcon = props => <Icon component={HelpOutlineSvg} style={{ fontSize: 16}} {...props} />;
export const StarIcon = props => <Icon component={StarSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
export const StarFillIcon = props => <Icon component={StarFillSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
