import React, { useState } from "react";
import userService from "../../../logics/services/user.service";
import apiService from "../../../logics/services/api.service";
import { Row, Col, Input, Button, Checkbox , Drawer, Select, message, Steps, Divider, Radio, Switch, Card, Avatar, Spin } from 'antd';
import Icon from '@ant-design/icons';
import { websocketService, socket } from "../../../logics/services/websocket.service";
import store from '../../../logics/store/case.store';

import ArbitrationSvg from  '../../../assets/svg-icons/arbitration.svg';
import StarSvg from  '../../../assets/svg-icons/star.svg';
import StarFillSvg from  '../../../assets/svg-icons/star_fill.svg';
import ArrowDownSvg from  '../../../assets/svg-icons/arrow_down.svg';

const ArbitrationIcon = props => <Icon component={ArbitrationSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const StarIcon = props => <Icon component={StarSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const StarFillIcon = props => <Icon component={StarFillSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const ArrowTopIcon = props => <Icon component={ArrowDownSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const ArrowDownIcon = props => <Icon component={ArrowDownSvg} className="arrow-down-nav-icon" style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;

const { Option } = Select;
const { TextArea } = Input;
const { Step } = Steps;

class Escalate extends React.Component {
    constructor(props) {
        super(props);
        this.caseId=this.props.caseId;
        this.visible=this.props.visible;
        this.escalateRequestVisible=this.props.escalateRequestVisible;
        this.escalationRequest=this.props.escalationRequest;
        this.caseInfo = [];
        this.escalateTypes = [];
        this.escalateType = '';
        this.resolutionsList = [];
        this.state = {
            caseInfo: [],
            loading: false,
            escalateVisible: this.visible,
            escalateRequestVisible: this.escalateRequestVisible,
            escalationRequest: this.escalationRequest,
            isDeclined: false,
            roleLabel: '',
            rejectReason: '',
            rejectComment: '',
            rejectConfirm: false,
            module: 'escalation',
            type: 'create',
            current: 0,
            escalateType: '',
            rcName: '',
            whatIs: '',
            escalateReason: '',
            comment: '',
            contractValue: '',
            mediatorSelectionDisable: false,
            neutralCheck: false,
            neutralChosse: false,
            escalateConfirm: false,
            policyConfirm: false,
            accClause: true,
            showYourMediator: false,
            mediatorSelection: true,
            manualSelection: true,
            showDetailInformation: false,
            termsCondition: false,
            terms: false,
            selectEscalteType: true,
            autoAllocate: true,
            isBioShow: false,
            acceptNeutralSelection: true,
            acceptRankPreference: false,
            acceptEscalate: false,
            acceptTerms: false,
            escalateTypes: [],
            mediators: [],
            escalateReasons: [],
            neutralList: []
        };
        this.submitDeclineEscalation = this.submitDeclineEscalation.bind(this);
    }

    componentDidMount = async () => {
        let response;
        const caseId = this.caseId;
        this.user = userService.getUser();
        this.setState({loading: true, roleLabel: this.user.role_label});
        this.caseInfo=store.getState().case;
        this.setState({caseInfo: store.getState().case});
        this.roles = store.getState().roles;
        this.getEscalationReason();
        if(this.escalateRequestVisible) {
            this.getMediatorList(this.escalationRequest.case_type);
        } else {
            this.getResolutions();
        }

        if (this.user.role_label == 'Case Officer' || this.user.role_label == 'Head Admin') {
            this.setState({mediatorSelectionDisable: true});
        }
        let element = document.getElementsByClassName('bottom-fixed-menu')[0];
        element.style.display = 'none';
    };

    getResolutions() {
        apiService.getResolutions()
            .then(res => {
                if (res.status == 'success') {
                    this.resolutionsList = res.data;
                    res.data.forEach(e => {
                        //this.setState({escalateTypes: this.state.escalateTypes.concat(e)});
                        if (this.caseInfo && this.caseInfo.case_type < e.rc_id && e.rc_id < 5) {
                            this.escalateTypes = this.escalateTypes.concat(e);
                        }
                    });
                    this.setState({escalateTypes: this.escalateTypes});
                    (this.escalateTypes && this.escalateTypes.length > 0) ? this.setState({escalateType: this.escalateTypes[0].rc_id, rcName: this.escalateTypes[0].rc_name, whatIs: this.escalateTypes[0].what_is}):'';
                    this.escalateType = (this.escalateTypes && this.escalateTypes.length > 0) ? this.escalateTypes[0].rc_id:'';
                    this.getMediatorList(this.escalateType);
                }
        })
    }

    getMediatorList(type) {
        apiService.getMediatorList({
            rc_id: type,
            allocation: 0,
            case_id: this.caseId
        })
        .then(res => {
            if (res.status == 'success') {
                this.setState({mediators: res.data});
            }
            this.setState({loading: false});
        });
    }

    getEscalationReason(type = 'create') {
		apiService.getCommonReasons({
            module_name: this.state.module,
            type: type
        })
        .then(res => {
            if(res.status == 'success') {
                this.setState({escalateReasons: []});
                res.data.forEach(e => {
                    this.setState({escalateReasons: this.state.escalateReasons.concat(e.reason)});
                });
                //(this.state.escalateReasons && this.state.escalateReasons.length > 0) ? this.setState({escalateReason: this.state.escalateReasons[0]}):'';
            }
        });
	}

    onClose = () => {
        this.getResolutions(); 
        this.setState({contractValue: '', showYourMediator: false, escalateReason: '', comment: '', showDetailInformation: false, selectEscalteType: true, mediatorSelection: true, termsCondition: false, terms: false, neutralList: [], escalateVisible: false});
        this.props.closeEscalateVisible(false);
        let element = document.getElementsByClassName('bottom-fixed-menu')[0];
        element.style.display = 'flex';
    };

    handleTypeChange = (type) =>  {
        this.setState({escalateType: type});
        this.escalateType = type;
        this.state.escalateTypes.forEach(e => {
            if(e.rc_id == type) {
                this.setState({rcName: e.rc_name, whatIs: e.what_is});
            }
        });
        if(type == 3 && this.state.caseInfo.case_type == 1) {
            this.setState({accClause: false});
        } else {
            this.setState({accClause: true});
        }
        this.getMediatorList(type)
    }

    handleReasonChange = (e) =>  {
        this.setState({escalateReason: e});
    }

    handleCommentChange = (e) =>  {
        this.setState({comment: e.target.value});
    }

    onContractChange = (e) => {
        this.setState({contractValue: e.target.value});
        if(e.target.value == 1) {
            this.setState({accClause: false});
            let neg = this.resolutionsList.filter(e => e.rc_id == 1);
            let rc_name_neg = neg && neg[0]?neg[0].rc_name:'Negotiation';
            let arb = this.resolutionsList.filter(e => e.rc_id == 3);
            let rc_name_arb = arb && arb[0]?arb[0].rc_name:'Arbitration';
            message.error(`You are not allowed to escalate from ${rc_name_neg} to ${rc_name_arb}`);
            // message.error('You are not allowed to escalate from Direct Dialogue to Arbitration');
        } else if(e.target.value == 0){
            this.setState({accClause: true});
        } 
    }

    handleEscalateConfirm = (terms) =>  {
        this.setState({terms: !terms});
    }

    handlePolicyConfirm = (termsCondition) =>  {
        this.setState({termsCondition: !termsCondition});
    }

    onNeutralCheck = (e) =>  {
        this.setState({mediatorSelection: e});
        if(!e && !this.state.showYourMediator) {
            this.setState({manualSelection: false});
        } else if(!e && this.state.showYourMediator){
            this.setState({manualSelection: true});
        } else {
            this.setState({manualSelection: true, showYourMediator: false, mediatorSelection: true});
        }
    }

    onNeutralChoose = (e) =>  {
        //this.setState({neutralChoose: e.target.checked});
        this.setState({showYourMediator: true});
        if(this.state.mediators.length >0 ) {
            this.setState({manualSelection: true});
        }
    }

    upMediatorChange(i) {
        if(i>0){
            let upMediators = this.state.mediators;
            let element = upMediators[i];
            let element1 = upMediators[i-1];
            upMediators[i] = element1;
            upMediators[i-1] = element;
            this.setState({mediators: upMediators});
        }
    }

    downMediatorChange(i) {
        if(i< 2){
            let downMediators = this.state.mediators;
            let element = downMediators[i];
            let element1 = downMediators[i+1];
            downMediators[i] = element1;
            downMediators[i+1] = element;
            this.setState({mediators: downMediators});
        }  
    }

    toggleBio(isBioShow) {
        this.setState({isBioShow: !isBioShow});
    }

    submitEscalate() {
        this.setState({loading: true});
        var neutralListIds = [];
        this.state.mediators.forEach(e => {
            neutralListIds = neutralListIds.concat(e.id);
        });
        //const objNeutralListIds = Object.assign({}, neutralListIds);
        this.setState({neutralList: neutralListIds});
        if(this.state.mediatorSelection) {
            this.setState({autoAllocate: true});
        } else {
            this.setState({autoAllocate: false});
        }
        apiService.caseEscalation({
            case_id:this.state.caseInfo.case_id,
            case_type:this.state.escalateType, 
            neutrals:{[this.user.id] : neutralListIds},
            esc_type:this.state.escalateType,
            reason:this.state.escalateReason,
            autoAllocate:this.state.autoAllocate,
            comment:this.state.comment,
            panel_size:0
        }).then(res => {
            if (res.status == 'success') {
                message.success(res.message);
                this.setState({loading:false, accClause: true});
                websocketService.send({
                    method:'escalate',
                    isDisplay: true,
                    data: {
                        users: res.data?res.data.parties.split(',').map(Number):[],
                        from: this.user.profile,
                        content: res.instant_msg,
                        esc_id: res.data?res.data.es_id:0,
                        type: 'escalation-request',
                        case_id: this.caseId
                    }
                });
                this.onClose();
            } else {
                message.error(res.message);
                this.setState({loading:false, accClause: true});
            }						
        });
    }

    next() {
        this.setState({showDetailInformation: true, showYourMediator: false, selectEscalteType: false});
    };

    prev() {
        //this.setState({current: this.state.current - 1});
        this.setState({selectEscalteType: true});
        if(!this.state.mediatorSelection && !this.state.showYourMediator) {
            this.setState({showYourMediator: true, termsCondition: false, terms: false, showDetailInformation: false});
        } else if(this.state.showYourMediator) {
            this.setState({showYourMediator: false});
        } else if(this.state.mediatorSelection && !this.state.showYourMediator){
            this.setState({showDetailInformation: false});
        }
    };

    onDecline = (e) => {
        let type = 'reject';
        if (this.state.roleLabel == 'Case Officer') {
            type = 'reject_approve';
            this.setState({type: 'reject_approve'});
        } else {
            type = 'reject';
            this.setState({type: 'reject'});
        }
        this.getEscalationReason(type);
        this.setState({rejectReason: '', rejectComment: '', isDeclined: true});
    }

    onDeclineBack = (e) => {
        this.setState({isDeclined: false});
    }

    handleRejectReasonChange = (e) =>  {
        this.setState({rejectReason: e});
    }

    handleRejectCommentChange = (e) =>  {
        this.setState({rejectComment: e.target.value});
    }

    handleConfirmRejectChange = (rejectConfirm) =>  {
        this.setState({rejectConfirm: !rejectConfirm});
    }

    submitDeclineEscalation() {
        this.setState({loading: true});
        apiService.caseEscalationReject({
            case_id: this.caseId,
            reason: this.state.rejectReason,
            comment: this.state.rejectComment,
            esc_id: this.state.escalationRequest.eStatus.es_es_id,
            esc_s_id: this.state.escalationRequest.eStatus.es_s_id
        }).then(res => {
            if (res.status == 'success') {
                message.success(res.message);
                this.onClose();
                this.setState({loading: false});
                websocketService.send({
                        method:'escalate',
                        isDisplay: true,
                        data: {
                            users: this.state.escalationRequest?this.state.escalationRequest.parties.split(',').map(Number):[],
                            content: res.instant_msg,
                            esc_id: this.state.escalationRequest.eStatus.es_es_id,
                            type: 'escalation-reject',
                            case_id: this.caseId
                        }
                    });
            } else {
                message.error(res.message);
            }
        },);
    }

    acceptEscalation() {
        this.setState({loading: true});
        var neutralListIds = [];
        this.state.mediators.forEach(e => {
            neutralListIds = neutralListIds.concat(e.id);
        });
        apiService.caseEscAccept({
            esc_s_id : this.state.escalationRequest.eStatus.es_s_id,
            esc_id : this.state.escalationRequest.eStatus.es_es_id,
            autoAllocate :  this.state.acceptNeutralSelection, 
            selected_neutral : {[this.user.id ] : neutralListIds},
        }).then(res => {
            if (res.status == 'success') {
                message.success(res.message);
                this.setState({acceptTerms: false, loading: false});
                websocketService.send({
                        method:'escalate',
                        isDisplay: true,
                        data: {
                            users: res.parties?res.parties.split(',').map(Number):[],
                            content: res.instant_msg,
                            esc_id: this.state.escalationRequest.eStatus.es_es_id,
                            type: 'escalation-accept',
                            time_display: false,
                            case_id: this.caseId
                        }
                    });
                this.onClose();
            } 						
        });
    }

    onAcceptNeutralSelection = (acceptNeutralSelection) =>  {
        this.setState({acceptNeutralSelection: !acceptNeutralSelection});
    }

    acceptEscalateNext = () => {
        if(!this.state.acceptNeutralSelection && !this.state.acceptRankPreference) {
            this.setState({acceptRankPreference: true})
        } else if(this.state.acceptRankPreference || this.state.acceptNeutralSelection) {
            this.setState({acceptRankPreference: false, acceptEscalate: true})
        }
    }

    acceptEscalatePrevious = () => {
        if(!this.state.acceptNeutralSelection && this.state.acceptRankPreference) {
            this.setState({acceptRankPreference: false})
        } else if(!this.state.acceptNeutralSelection && this.state.acceptEscalate) {
            this.setState({acceptRankPreference:true, acceptEscalate: false})
        } else if(this.state.acceptEscalate || this.state.acceptNeutralSelection) {
            this.setState({acceptEscalate: false})
        }
    }

    handleAcceptEscalateConfirm = (acceptTerms) =>  {
        this.setState({acceptTerms: !acceptTerms});
    }

    render() {
        const { escalateVisible, escalateTypes, loading, caseInfo, current, escalateType, rcName, whatIs, escalateReasons, escalateReason, contractValue, mediatorSelectionDisable, mediatorSelection, showYourMediator, mediators, manualSelection, accClause, showDetailInformation, termsCondition, terms, selectEscalteType, escalateRequestVisible, escalationRequest, isDeclined, roleLabel, rejectReason, rejectComment, rejectConfirm, isBioShow, acceptNeutralSelection, acceptRankPreference, acceptEscalate, acceptTerms } = this.state;

        let steps = [];
        if(selectEscalteType) {
            steps = [
                {
                    id: 0,
                    content: <>
                        <Row className="mb16">
                            <Col span={24}>
                                <p className="mb8">Escalate this case to</p>
                            </Col>
                            <Col span={24}>
                                <Select name="escalate-type" value={escalateType} id="escalate-type" style={{ width: "100%" }} onChange={this.handleTypeChange}>
                                    { escalateTypes && escalateTypes.length > 0 
                                        ? escalateTypes.map((item, index) => (
                                            <React.Fragment key={[index]}>
                                                <Option value={item.rc_id}>{item.rc_name}</Option>
                                            </React.Fragment>
                                        ))
                                        :
                                            <></>
                                    }
                                </Select>
                            </Col>
                        </Row>
    
                        <Row>
                            <Col span={24}>
                                <p className="mb8">What is {rcName ? rcName+"?": ""}</p>
                            </Col>
                            <Col span={24}>
                                <span dangerouslySetInnerHTML={{ __html: whatIs }}></span>
                            </Col>
                        </Row>
    
                        <Divider />
    
                        <Row className="mb16">
                            <Col span={24}>
                                <p className="mb8">Why do you want to escalate?</p>
                            </Col>
                            <Col span={24}>
                                <Select name="escalate-reason" id="escalate-reason" value={escalateReason != '' ? escalateReason: undefined} className="mb10" placeholder="Select a reason" style={{ width: "100%" }} onChange={this.handleReasonChange}>
                                    { escalateReasons && escalateReasons.length > 0 
                                        ? escalateReasons.map((item, index) => (
                                            <React.Fragment key={[index]}>
                                                <Option value={item}>{item}</Option>
                                            </React.Fragment>
                                        ))
                                        :
                                            <></>
                                    }
                                </Select>
                                <TextArea name="escalate-comment" id="escalate-comment" rows={6} placeholder="Do you want to specify anything? (Optional)" onChange={this.handleCommentChange} />
                            </Col>
                        </Row>
                        
                        { caseInfo && caseInfo.case_type == 1 && escalateType == 3
                            ?
                                <Row className="mb16">
                                    <Col span={24}>
                                        <p className="mb8">Have you signed an { rcName } Clause in your Contract?</p>
                                    </Col>
                                    <Col span={24}>
                                        <Radio.Group name="escalate-contract" onChange={this.onContractChange} value={contractValue}>
                                            <Radio value={0}>Yes</Radio>
                                            <Radio value={1}>No</Radio>
                                        </Radio.Group>
                                    </Col>
                                </Row>
                            :
                                <></>
                        }
                        
                        <Row className="neutral-selection mb16 pb32">
                            <Col span={24}>
                                <p className="mb8">Neutral Selection</p>
                            </Col>
                            <Col span={24}>
                                <Row className="mb10">
                                    <Col span={20}>
                                        <p>I want the Platform to allocate the next available {escalateType == 2 ? this.roles.Mediator: (escalateType == 3 ? this.roles.Arbitrator: '') } for my case</p>
                                    </Col>
                                    <Col span={3}>
                                        <Switch id="neutral-check" disabled={mediatorSelectionDisable} checked={mediatorSelection} onChange={this.onNeutralCheck} />
                                    </Col>
                                </Row>
    
                                {   !mediatorSelection
                                    ?
                                        <Row>
                                            <Col span={17}>
                                                <p>I want to choose my own {escalateType == 2 ? this.roles.Mediator: this.roles.Arbitrator }. </p>
                                                <span>Once you and the other party make your selection, we will try and provide the closest match.</span>
                                            </Col>
                                            <Col span={6}>
                                                <Button id="neutral-choose" type="primary" disabled={showYourMediator} onClick={this.onNeutralChoose}>Choose</Button>
                                            </Col>
                                        </Row>
                                    :
                                        <></>
                                }
                            </Col>
                        </Row>
                    </>,
                }
            ];
        }

        if(showYourMediator && !mediatorSelection) {
            steps = [
                {
                    id: 1,
                    content: <>
                        <Row className="mb16">
                            <Col span={24}>
                                <p className="mb8">Rank {escalateType == 2 ? this.roles.Mediator: this.roles.Arbitrator } based on your preference</p>
                            </Col>
                            <Col span={24}>
                                { mediators && mediators.length > 0 
                                    ? mediators.map((item, index) => (
                                        <Card className="rank-preference mb8 " key={index}>
                                            <Row>
                                                <Col span={2} className="sno">
                                                    {index+1}
                                                </Col>
                                                <Col span={22} className="created-details">
                                                    <Row>
                                                        <Col span={4} className="avatar"><Avatar src={item.user_image}>{item.p_name}</Avatar></Col>
                                                        <Col span={16}>
                                                            <Row>
                                                                <Col className="name">{item.name}</Col>
                                                            </Row>
                                                            <Row>
                                                                <Col className="role">{item.role_name}</Col>
                                                            </Row>
                                                        </Col>
                                                        <Col span={4}>
                                                            <Row className="icon">
                                                                <Col span={12}><ArrowTopIcon onClick={() => this.upMediatorChange(index)} /></Col>
                                                                <Col span={12}><ArrowDownIcon onClick={mediators.length > 1 && (() => this.downMediatorChange(index))} /></Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row className="pt10">
                                                        { item.bio && item.bio.length >= 250
                                                            ?
                                                                <Col>
                                                                    {   !isBioShow
                                                                        ?
                                                                            <span className="bio">{item.bio.substring(0, 250)}</span>
                                                                        :
                                                                            <span className="bio">{item.bio}</span>
                                                                    }
                                                                    <a onClick={() => this.toggleBio(isBioShow)} className="bio-readmore" style={{color:"#136796"}}>{ !isBioShow?'...Read More':' Hide' }</a>
                                                                </Col>
                                                            :
                                                                <Col>
                                                                    <span className="bio">{item.bio}</span>
                                                                </Col>
    
                                                        }
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    ))
                                    :
                                        <></>
                                }
                                <br />
                            </Col>
                        </Row>
                    </>,
                }
            ];
        }
        
        if(showDetailInformation) {
            steps = [
                {
                    id: 2,
                    content: <>
                        <Row className="mb16">
                            <Col span={24}>
                                <p className="mb8">{rcName} Policy</p>
                            </Col>
                            <Col span={24}>
                                <span className="mb8">
                                    1. We agree to take turns speaking and to try to not interrupt each other. 
                                    <br/><br/>
                                    2. We agree to call each other by our first names, not "he" or "she" or worse. 
                                    <br/><br/>
                                    3. We will ask questions of each other for the purposes of gaining clarity and 
                                    understanding and not as attacks. 
                                    <br/><br/>
                                    4. We agree to try to avoid establishing hard positions and express ourselves in 
                                    terms of our needs and desires and the outcomes that we wish to create. 
                                    <br/><br/>
                                    5. We agree to listen respectfully and sincerely try to understand the other's 
                                    needs and interests. 
                                    <br/><br/>
                                    6. We recognize that, even if we do not agree with it, each of us is entitled to our 
                                    own perspective. 
                                    <br/><br/>
                                    7. We will seek to avoid dwelling on things that did not work in the past, and 
                                    instead focus on the future we want to create. 
                                    <br/><br/>
                                    8. We agree to make a conscious, sincere effort to refrain from unproductive 
                                    arguing, venting, and narration and agree to use our time in mediation to work 
                                    toward what we perceive to be our most constructive agreement possible. 
                                    <br/><br/>
                                    9. We will speak up if something is not working for us in the resolution process. 
                                    <br/><br/>
                                    10. We will request a break if helpful. 
                                    <br/><br/>
                                    11. While in the resolution process, we will refrain from furthering adversarial legal 
                                    proceedings, except in the case of an emergency necessitating such action. 
                                    <br/><br/>
                                    12. We will point out if we feel the Neutral is not impartial as to the person or the result.
                                </span>
                            </Col>
                        </Row>
                        <Row className="mb16 pb32">
                            <Col span={24} className="pb8">
                                <Checkbox id="escalate-terms-confirm" checked={terms} onChange={() => this.handleEscalateConfirm(terms)}>I am sure I want to escalate this case</Checkbox>
                            </Col>
                            <Col span={24}>
                                <Checkbox id="escalate-policy-confirm" checked={termsCondition} onChange={() => this.handlePolicyConfirm(termsCondition)}>I agree to the policy mentioned above.</Checkbox>
                            </Col>
                        </Row>
                    </>,
                }
            ];
        }

        return (
            <div>
                
                <Drawer
                    title="Case Escalation"
                    className="escalate-drawer"
                    placement="top"
                    height={"100%"}
                    onClose={this.onClose}
                    visible={escalateVisible}
                    >
                        <Row className="sub-header case-name-header">
                            <Col span={20} className="case-name-bar">
                                <ArbitrationIcon className="fill-svg-w" />
                                <span className="pl16 case-name">{caseInfo ? caseInfo.case_name:''}</span>
                                <span className="pl8">
                                    { !caseInfo?.isFav ?<StarIcon />:<StarFillIcon /> }
                                </span>
                            </Col>
                        </Row>

                        <Spin spinning={loading} delay={500}>

                            <Row className="escalate-content p15">
                                {steps[current].content}
                            </Row>
                            
                            <Row className="escalate-footer">
                                <Col span={12}>
                                    {   (!showYourMediator && !showDetailInformation) && 
                                        (<Button type="primary" id="escalate-cancel" className="escalate-cancel" onClick={this.onClose}>Cancel</Button>)
                                    }
                                    {   (showYourMediator || showDetailInformation) && 
                                        (<Button type="primary" id="escalate-previous" className="escalate-cancel" onClick={() => this.prev()}>Previous</Button>)
                                    }
                                </Col>
                                <Col span={12}>
                                    {   !showDetailInformation && 
                                        (<Button type="primary" id="escalate-next" className="escalate-submit" disabled={escalateReason == '' || (!mediatorSelection && !showYourMediator) || !manualSelection || !accClause || (!showYourMediator && escalateReason == '') ? true:false} onClick={() => this.next()}>Next</Button>)
                                    }
                                    {   showDetailInformation && 
                                        (<Button type="primary" id="escalate-submit" className="escalate-submit" disabled={!terms || !termsCondition ? true:false} onClick={() => this.submitEscalate()}>Escalate</Button>)
                                    }
                                </Col>
                            </Row>

                        </Spin>
                </Drawer>

                <Drawer
                    title="Escalate Request"
                    className="escalate-drawer"
                    placement="top"
                    height={"100%"}
                    onClose={this.onClose}
                    visible={escalateRequestVisible}
                    >
                        <Row className="sub-header case-name-header">
                            <Col span={20} className="case-name-bar">
                                <ArbitrationIcon className="fill-svg-w" />
                                <span className="pl16 case-name">{caseInfo ? caseInfo.case_name:''}</span>
                                <span className="pl8">
                                    <StarIcon />
                                </span>
                            </Col>
                        </Row>

                        <Spin spinning={loading} delay={500}>
                            { escalationRequest
                                ? 
                                    <>
                                        <Row className="escalate-content p15">
                                            {  !isDeclined && (caseInfo.case_status == 3 || caseInfo.case_status == 2) && escalationRequest.eStatus && escalationRequest.eStatus.status == 0 && escalationRequest.status !=2 && !acceptEscalate && !acceptRankPreference
                                                ?
                                                    <>
                                                        <Card className="escalate-accept-reject mb8">
                                                            <Row className="escalate-detail">
                                                                <Row className="mb8">
                                                                    <Col span={15} className="escalate-by-container">
                                                                        <Row>
                                                                            <Col className="escalate-by mb8">Escalated by</Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col span={5}><Avatar src={escalationRequest.requested_by.user_image}>{escalationRequest.requested_by.p_name}</Avatar></Col>
                                                                            <Col span={18}>
                                                                                <Row className="escalate-name">{escalationRequest.requested_by.name}</Row>
                                                                                <Row className="escalate-by-role">{escalationRequest.requested_by.role_name}</Row>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col span={9} className="escalate-to-container">
                                                                        <Row>
                                                                            <Col className="escalate-to mb8">Escalate to</Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col className="escalate-role">{escalationRequest.case_type_name}</Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="mb8">
                                                                    <Col span={24} className="mb4">
                                                                        <span className="reason-title">Reason</span>
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <p className="reason">{escalationRequest.escalate_reason}</p>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col span={24} className="mb4">
                                                                        <span className="comment-title">Comments</span>
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <p className="comment">{escalationRequest.escalate_comment}</p>
                                                                    </Col>
                                                                </Row>
                                                            </Row>
                                                        </Card>

                                                        <Row className="neutral-selection mb16 pb32">
                                                            <Col span={24}>
                                                                <p className="mb8">{escalationRequest.case_type == 2 ? this.roles.Mediator: (escalationRequest.case_type == 3 ? this.roles.Arbitrator: '') } Selection</p>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Row className="mb10">
                                                                    <Col span={20}>
                                                                        <p>I want the Platform to allocate the most suitable {escalationRequest.case_type == 2 ? this.roles.Mediator: (escalationRequest.case_type == 3 ? this.roles.Arbitrator: '') } for my case.</p>
                                                                    </Col>
                                                                    <Col span={3}>
                                                                        <Switch id="accept-neutral-selection" checked={acceptNeutralSelection} onChange={() => this.onAcceptNeutralSelection(acceptNeutralSelection)} />
                                                                    </Col>
                                                                </Row>

                                                                {   !acceptNeutralSelection
                                                                    ?
                                                                        <Row>
                                                                            <Col>
                                                                                <p>I want to choose my own {escalationRequest.case_type == 2 ? this.roles.Mediator: (escalationRequest.case_type == 3 ? this.roles.Arbitrator: '') }. </p>
                                                                                <span>Once you and the other party make your selection, we will try and provide the closest match.</span>
                                                                            </Col>
                                                                        </Row>
                                                                    :
                                                                        <></>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </>
                                                :
                                                    <></>
                                            }

                                            {   acceptRankPreference
                                                ?
                                                    <Row className="mb16">
                                                        <Col span={24}>
                                                            <p className="mb8">Rank {escalationRequest.case_type == 2 ? this.roles.Mediator: (escalationRequest.case_type == 3 ? this.roles.Arbitrator: '') } based on your preference</p>
                                                        </Col>
                                                        <Col span={24}>
                                                            { mediators && mediators.length > 0 
                                                                ? mediators.map((item, index) => (
                                                                    <Card className="rank-preference mb8 " key={index}>
                                                                        <Row>
                                                                            <Col span={2} className="sno">
                                                                                {index+1}
                                                                            </Col>
                                                                            <Col span={22} className="created-details">
                                                                                <Row>
                                                                                    <Col span={4} className="avatar"><Avatar src={item.user_image}>{item.p_name}</Avatar></Col>
                                                                                    <Col span={16}>
                                                                                        <Row>
                                                                                            <Col className="name">{item.name}</Col>
                                                                                        </Row>
                                                                                        <Row>
                                                                                            <Col className="role">{item.role_name}</Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                                    <Col span={4}>
                                                                                        <Row className="icon">
                                                                                            <Col span={12}><ArrowTopIcon onClick={() => this.upMediatorChange(index)} /></Col>
                                                                                            <Col span={12}><ArrowDownIcon onClick={mediators.length > 1 && (() => this.downMediatorChange(index))} /></Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className="pt10">
                                                                                    { item.bio && item.bio.length >= 250
                                                                                        ?
                                                                                            <Col>
                                                                                                {   !isBioShow
                                                                                                    ?
                                                                                                        <span className="bio">{item.bio.substring(0, 250)}</span>
                                                                                                    :
                                                                                                        <span className="bio">{item.bio}</span>
                                                                                                }
                                                                                                <a onClick={() => this.toggleBio(isBioShow)} className="bio-readmore" style={{color:"#136796"}}>{ !isBioShow?'...Read More':' Hide' }</a>
                                                                                            </Col>
                                                                                        :
                                                                                            <Col>
                                                                                                <span className="bio">{item.bio}</span>
                                                                                            </Col>
                                
                                                                                    }
                                                                                </Row>
                                                                            </Col>
                                                                        </Row>
                                                                    </Card>
                                                                ))
                                                                :
                                                                    <></>
                                                            }
                                                            <br />
                                                        </Col>
                                                    </Row>
                                                :
                                                    <></>
                                            }

                                            {   acceptEscalate
                                                ?
                                                    <>
                                                        <Row className="mb16">
                                                            <Col span={24}>
                                                                <p className="mb8">{ escalationRequest.case_type_name?escalationRequest.case_type_name:(escalationRequest.case_type == 2 ? 'Mediation': (escalationRequest.case_type == 3 ? 'Arbitration': '')) } Policy</p>
                                                            </Col>
                                                            <Col span={24}>
                                                                <span className="mb8">
                                                                1. We agree to take turns speaking and to try to not interrupt each other. 
                                                                <br/><br/>
                                                                2. We agree to call each other by our first names, not "he" or "she" or worse. 
                                                                <br/><br/>
                                                                3. We will ask questions of each other for the purposes of gaining clarity and 
                                                                understanding and not as attacks. 
                                                                <br/><br/>
                                                                4. We agree to try to avoid establishing hard positions and express ourselves in 
                                                                terms of our needs and desires and the outcomes that we wish to create. 
                                                                <br/><br/>
                                                                5. We agree to listen respectfully and sincerely try to understand the other's 
                                                                needs and interests. 
                                                                <br/><br/>
                                                                6. We recognize that, even if we do not agree with it, each of us is entitled to our 
                                                                own perspective. 
                                                                <br/><br/>
                                                                7. We will seek to avoid dwelling on things that did not work in the past, and 
                                                                instead focus on the future we want to create. 
                                                                <br/><br/>
                                                                8. We agree to make a conscious, sincere effort to refrain from unproductive 
                                                                arguing, venting, and narration and agree to use our time in mediation to work 
                                                                toward what we perceive to be our most constructive agreement possible. 
                                                                <br/><br/>
                                                                9. We will speak up if something is not working for us in the resolution process. 
                                                                <br/><br/>
                                                                10. We will request a break if helpful. 
                                                                <br/><br/>
                                                                11. While in the resolution process, we will refrain from furthering adversarial legal 
                                                                proceedings, except in the case of an emergency necessitating such action. 
                                                                <br/><br/>
                                                                12. We will point out if we feel the Neutral is not impartial as to the person or the result.
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb16 pb32">
                                                            <Col span={24} className="pb8">
                                                                <Checkbox id="escalate-terms-confirm" checked={acceptTerms} onChange={() => this.handleAcceptEscalateConfirm(acceptTerms)}>I accept the escalation request and agree to the policy mentioned above.</Checkbox>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                :
                                                    <></>
                                            }
                                            
                                            {   isDeclined
                                                ?
                                                    <>
                                                        <Row className="mb16">
                                                            <Col span={24} className="decline-case-title">
                                                            {roleLabel == 'Case Officer'?'Reject':'Decline'} case escalation
                                                            </Col>
                                                        </Row>

                                                        <Row className="mb16">
                                                            <Col span={24}>
                                                                <p className="mb8">Please indicate a reason for {roleLabel == 'Case Officer'?'rejection':'decline'}:</p>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Select name="escalate-decline-reason" value={rejectReason} id="escalate-decline-reason" style={{ width: "100%" }} placeholder="Select a reason" onChange={this.handleRejectReasonChange}>
                                                                    { escalateReasons && escalateReasons.length > 0 
                                                                        ? escalateReasons.map((item, index) => (
                                                                            <React.Fragment key={[index]}>
                                                                                <Option value={item}>{item}</Option>
                                                                            </React.Fragment>
                                                                        ))
                                                                        :
                                                                            <></>
                                                                    }
                                                                </Select>
                                                            </Col>
                                                        </Row>
                                                        
                                                        <Row className="mb16">
                                                            <Col span={24}>
                                                                <Row>
                                                                    <Col span={14}>
                                                                        <p className="mb8">Comment (optional)</p>
                                                                    </Col>
                                                                    <Col span={10}>
                                                                        <span className="comment-character-length">{rejectComment ? rejectComment.length: 0}/300 Characters left</span>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col span={24}>
                                                                <TextArea maxLength={300} name="escalate-decline-comment" id="escalate-decline-comment" value={rejectComment} rows={6} onChange={this.handleRejectCommentChange} />
                                                            </Col>
                                                        </Row>

                                                        <Row className="mb16">
                                                            <Col span={24}>
                                                                <Checkbox id="decline-confirm-checkbox" className="decline-confirm-checkbox" onChange={() => this.handleConfirmRejectChange(rejectConfirm)}>I am sure I want to {roleLabel == 'Case Officer'?'Reject':'Decline'} the request to escalate this case.</Checkbox>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                :
                                                    <></>
                                            }
                                        </Row>

                                        <Row className="escalate-footer">
                                            <Col span={12}>
                                                {   !isDeclined && !acceptRankPreference && !acceptEscalate &&
                                                    (<Button type="primary" id="escalate-decline" className="escalate-decline" disabled={escalationRequest.escAccCount == 0 && roleLabel == 'Case Officer' ? true:false} onClick={() => this.onDecline(escalationRequest)}>{roleLabel == 'Case Officer'?'Reject':'Decline'}</Button>)
                                                }
                                                {   !isDeclined && (acceptRankPreference || acceptEscalate) &&
                                                    (<Button type="primary" id="escalate-accept-previous" className="escalate-decline" onClick={() => this.acceptEscalatePrevious()}>Previous</Button>)
                                                }
                                                {   isDeclined && 
                                                    (<Button type="primary" id="escalate-confirm-decline" className="escalate-confirm-decline" disabled={rejectReason == '' || !rejectConfirm? true: false} onClick={this.submitDeclineEscalation}>{roleLabel == 'Case Officer'?'Reject':'Decline'}</Button>)
                                                }
                                            </Col>
                                            <Col span={12}>
                                                {   (!isDeclined || acceptRankPreference) && !acceptEscalate &&
                                                    (<Button type="primary" id="escalate-accept-next" className="escalate-accept" onClick={() => this.acceptEscalateNext()}>Next</Button>)
                                                }
                                                {   !isDeclined && acceptEscalate &&
                                                    (<Button type="primary" id="escalate-accept" className="escalate-accept" disabled={!acceptTerms} onClick={() => this.acceptEscalation()}>{roleLabel == 'Case Officer'?'Approve':'Accept'}</Button>)
                                                }
                                                {   isDeclined && 
                                                    (<Button type="primary" id="escalate-back" className="escalate-back" onClick={this.onDeclineBack}>Back</Button>)
                                                }
                                            </Col>
                                        </Row>
                                    </>
                                :
                                    <></>
                            }
                        </Spin>

                </Drawer>
            </div>            
        );
    }
}

export default Escalate;